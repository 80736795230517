
import {computed, defineComponent, ref} from 'vue'
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import store from "@/store";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import ScanPriorityLabel from "@/views/scan/ScanPriorityLabel.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import InfoBox from "@/components/base/InfoBox.vue";
import Chart from "@/views/chart/Chart.vue";
import {ChartCode, ChartType} from "@/core/config/Constant";
import Swal from "sweetalert2";
import ScanService from "@/core/services/ScanService";
import {useToast} from "vue-toast-notification";

export default defineComponent({
  name: "List",
  computed: {
    ChartCode() {
      return ChartCode
    },
    ChartType() {
      return ChartType
    }
  },
  components: {Chart, InfoBox, DateTimeFormat, UserListSelect, BaseSelect, ScanPriorityLabel, KtDatatable, Entities},
  setup() {
    const loading = ref(false);
    setCurrentPageBreadcrumbsWithParams('All Data Problems',
      [
        {link: false, router: '', text: 'Data Consistency'},
        {link: false, router: '', text: 'All Data Problems'}
      ]
    )

    const filterObject = ref({priority: '', objectType: '', ownerId: ''})
    const page = computed(() => store.state.ScanModule.page);
    const tableHeader = ref([
      {
        name: "Problem Discovered",
        key: "date",
        sortable: true,
      },
      {
        name: "Entity",
        key: "entity",
        sortable: true,
      },
      {
        name: "Owner",
        key: "owner",
        sortable: true,
      },
      /*
            {
              name: "Name",
              key: "name",
              sortable: true,
            },
      */
      {
        name: "Description",
        key: "desc",
        sortable: true,
      },
      {
        name: "Priority",
        key: "priority",
        sortable: true,
      },
    ]);
    return {
      ...LoadFilterObjects(Actions.LOAD_SCANS, filterObject.value, ['owner']),
      page,
      filterObject,
      ...LoadPanel(),
      tableHeader,
      loading,

    }
  },
  methods: {
    onDiscard() {
      this.filterObject.priority = '';
      this.filterObject.objectType = '';
      this.filterObject.ownerId = '';
      this.paginationLoad()
    },
    onSearch() {
      this.updateFilterObject(this.filterObject)
    },
    toName(objectType) {
      switch (objectType) {
        case 'CASE' :
          return 'Case'
        case 'CLAIM' :
          return 'Claim'
        case 'INVOICE' :
          return 'Invoice'
      }
    },
    refresh() {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      this.loading = true;
      ScanService.refresh().then(() => {
        useToast().success('Recheck my data problem successfully', {position: 'top-right'})
        this.loading = false
      }).finally(() => {
        Swal.close();
      })
    }
  }
})
